/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-10-17",
    versionChannel: "nightly",
    buildDate: "2024-10-17T00:06:10.933Z",
    commitHash: "41598c6fdb7ee797f0a8ee86f25f9c9cd1b9b39a",
};
